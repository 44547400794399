//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FranchisorAccountProgress from "@/components/progress/FranchisorAccountProgress";
import OnBoardIcon from "~/assets/images/icons/line-dot.svg?inline";
import AccountFranchisorLayout from "../../components/account-franchisor/AccountFranchisorLayout";
import BaseButton from "@/components/buttons/BaseButton"

export default {
  name:'OnBoardingPacketPage',
  components: {
    BaseButton,
    FranchisorAccountProgress,
    AccountFranchisorLayout,
    OnBoardIcon,
  },
  data: () => ({
      activeItem: 'Onboarding Packet',
      packets: [
        { title: "Brand Questionnaire", link:"/1Yhc8z-5av5LdaJ35AJNEdEIZPrb0BW8DT8UNCUzSnhA/edit" },
        { title: "Marketing Questionnaire", link:"/1mFvYQwncNcd1x3veytTfb3-zCN50LxfpPqTKA9NvlAA/edit" },
        { title: "Technology and Operations Questionnaire", link:"/1huEGhX_DKch0IlAVpGPMJpl9xB4jQz9PTAKJIJINA64/edit" },
        { title: "Real Estate and Construction Questionnaire", link:"/1zPkU6Y_yCR0oEmIBXwYFMhXs7Bkluj_NeCB1xO8UW2g/edit" },
        { title: "Brand Web Page Content - Franchise123", link:"/1_rNckR6mOuP953s4L6V9xHaDUWPed3q0cMb4jwM1ul0/edit" },
      ],
      materials:[
        { label: 'a', title:'Most recent FDD and state registration status' },
        { label: 'b', title:'Franchise Information Report or PDF summary of the franchise' },
        { label: 'c', title:'A high resolution logo, preferably in a vector format (.eps, .cdr, .svg, .ai)' },
        { label: 'd', title:'All approved marketing images and graphics, including promotional materials for special events, campaigns, etc.' },
        { label: 'e', title:'Any brand'},
        { label: 'f', title:'A brand guide, if available (or, at minimum, please provide any design or brand specifications such as approved colors, fonts, slogans, etc.)' },
        { label: 'g', title:'A branded PowerPoint template, if available' },
        { label: 'h', title:'or photos of the core leadership team' },
      ],
      timeLines: [
        { title: 'Weeks 1 & 2', text:'Franchise Accelerators will draft an Email Drip Campaign and a series of Sales Presentations for franchisee candidates.' },
        { title: 'Week 3', text:'Review all content created by Franchise Accelerators to ensure the brand is being represented accurately.' },
        { title: 'Week 4', text:'Upon approval, we will launch the Brand Landing Page and Blog on' },
        { title: 'Ongoing', text:'Lead generation, blog posts, newsletters, and other collateral as needed.' },
      ],
  }),
  methods: {
    getActiveItem(params) {
      if(params) {
        this.activeItem = params;
      }
    },
    showItemNumber(index) {
      return index < 9 ? `0${index + 1}` : `${index + 1}`;
    },
    setClass(label) {
      return label.replace(/\s/g, '-').toLowerCase();
    }
  }
}
