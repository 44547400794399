//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import {mapActions, mapGetters} from "vuex"
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
import Loader from "@/components/Loader"
import ModalMembershipChange from "@/components/modals/ModalMembershipChange"
import ModalMembershipBlock from "@/components/modals/ModalMembershipBlock"

import {
  PAYMENT_MEMBERSHIP_LIST,
} from "@/store/constants"

export default {

  middleware: [
    'accountFranchisor'
  ],
  components: {
    Loader,
    ModalMembershipChange,
    ModalMembershipBlock,
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  async asyncData({store, error}) {
    const user = store.getters['auth/user']

    if (!user.isFranchisor) {
      error({statusCode: 403})
    }

    await store.dispatch('payment/fetchPayment', user.id)
  },
  data() {
    return {
      loading: true,
      mySwiper: null,
      windowWidth: 0,
      mobileWindowWidth: 769,
      swiperOption: {
        slidesPerView: 1.35,
        centeredSlides: true,
        spaceBetween: 16,
        loop: true,
        breakpoints: {
          769: {
            slidesPerView: 'auto',
            centeredSlides: false,
            spaceBetween: 8,
            loop: false,
            touchRatio: 0
          }
        },
        on: {
          slideChangeTransitionEnd(e) {
            const firstSlideReal = 2
            const changeSliderSpeed = 0
            const defaultShift = 2
            const slidesAmount = e.slides.length
            const firstSlideClone = slidesAmount - defaultShift
            if (e.activeIndex === firstSlideClone) {
              e.slideTo(firstSlideReal, changeSliderSpeed)
            }
          }
        }
      },
      showModalChange: false,
      showModalBlock: false,
      membershipPlan: null,
      currentCustomer: null,
      paymentInfo: new Form({
        sessionId: null
      }),
      form: new Form({
        firstname: null,
        lastname: null,
        email: null,
        franchisor_subscription: null,
        hubspotutk: null
      }),
      accountLink: '/account-franchisor/dashboard',
      PAYMENT_MEMBERSHIP_LIST,
    }
  },
  watch: {
    windowWidth(newVal, oldVal) {
      if (process.client) {
        if (newVal > this.mobileWindowWidth) {
          this.swiperOption.loop = false
          this.swiperOption.breakpoints.loop = false
        }
        if (oldVal !== 0) {
          this.loading = true
          window.location.reload()
        }
      }
    }
  },
  created() {
    if (process.client) {
      this.windowWidth = window.outerWidth
      window.addEventListener("resize", this.resizeEventHandler)
    }
  },
  head() {
    return {
      title: "Membership",
      meta: [
        {
          name: 'description',
          hid: "description",
          property: "description",
          content:
            "Franchise123 Membership page"
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          hid: "og:title",
          property: "og:title",
          content: "Membership | Franchise123"
        },
        {
          hid: "og:url",
          property: "og:url",
          content: process.env.baseUrl
        },
        {
          hid: "og:description",
          property: "og:description",
          content:
            ""
        }
      ],
      bodyAttrs: {
        class: 'membership-page'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      paymentUser: 'payment/user',
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  async beforeMount() {
    if (this.user === null) {
      await this.$router.push('/')
    }
  },
  mounted() {
    this.loading = false

    this.form.firstname = this.user.first_name
    this.form.lastname = this.user.last_name
    this.form.email = this.user.email
    this.form.page_uri = `${document.location.protocol}//${document.location.host}/account-franchisor`
    this.form.hubspotutk = this.$cookies.get('hubspotutk')
  },
  destroyed() {
    if (process.client) {
      window.removeEventListener("resize", this.resizeEventHandler)
    }
  },
  methods: {
    ...mapActions({
      fetchPayment: 'payment/fetchPayment'
    }),
    async selectPlan(plan) {
      this.loading = true
      this.membershipPlan = plan
      this.form.franchisor_subscription = plan

      if (plan !== this.paymentUser.membership_level) {
        await this.setSubscriptionPlan()
        await this.setMembership()
        await this.fetchPayment(this.user.id)
      }

      if (plan === this.PAYMENT_MEMBERSHIP_LIST.Later) {
        await this.$router.push(this.accountLink)
        return
      }

      await this.openModal()
    },
    async openModal() {
      if (this.paymentUser.signed_contract) {
        this.showModalChange = true
      } else if (!this.paymentUser.signed_contract) {
        this.showModalBlock = true
      }
    },
    async setSubscriptionPlan() {
      try {
        await this.form.post('/api/v1/other/franchisor-subscription-plan')
      } catch (e) {
        console.log(e)
      }
    },
    async setMembership() {
      try {
        await this.form.post('/api/v1/payment/set-membership')
        await this.fetchPayment(this.user.id)
      } catch (e) {
        console.log(e)
      }
    },
    async providePayment() {
      try {
        const {data} = await this.form.post('/api/v1/payment')
        const checkoutSessionId = data.result.checkoutSessionId

        /*const stripe = await loadStripe(process.env.STRIPE_API_KEY)
        await stripe.redirectToCheckout({
          sessionId: checkoutSessionId
        })*/

      } catch (e) {
        console.log(e)
      }

      await this.fetchPayment(this.user.id)
    },
    async closeModalChange(action) {
      this.showModalChange = false
      await this.$router.push(this.accountLink)
    },
    async closeModalBlock() {
      this.showModalBlock = false
      await this.$router.push(this.accountLink)
    },
    resizeEventHandler(e) {
      this.windowWidth = e.target.outerWidth
    }
  },
}
