import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7e921d8e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7e921d8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e921d8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('E:/all-projects/franchise-digital-platform-spa/components/Loader.vue').default})
