//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DashboardFranchiseeMatches from "@/components/dashboards/viewFranchisee/DashboardFranchiseeMatches";
import {mapActions} from "vuex";

const tabs = [
  'dashboard',
  'profile',
  'franchise-university',
  'favorite-franchises',
  'comparisons'
]

export default {
  name: 'PageAccountMyMatches',
  scrollToTop: true,
  components: {
    DashboardFranchiseeMatches,
  },
  data:() =>({
    tabs,
    checkedTab: tabs[0],
  }),
  async mounted() {
    const currentPage = this.$route.query.page ?? 1
    await this.fetchPreferences()
    await this.updateCurrentPage(currentPage)
    await this.fetchFranchises()
  },
  async created() {
    const tab = this.$route.params.tab
    if (tab !== null && tabs.indexOf(tab) !== -1) {
      this.checkedTab = tab
    }
    this.setShowPreloader(true)
  },
  methods:{
    ...mapActions({
      fetchFranchises: 'account/fetchFranchises',
      updateCurrentPage: 'account/updateCurrentPage',
      fetchPreferences: 'account/fetchPreferences',
      setShowPreloader: 'account/setShowPreloader',
    }),
  }
}
