//
//
//
//
//
//
//
//
//

import 'vue-loading-overlay/dist/vue-loading.css'
import CompanyPageLandingNew from "@/components/CompanyPageLandingNew"
import {mapGetters} from "vuex"

export default {
  layout: "landing",
  components: {
    CompanyPageLandingNew,
  },
  async asyncData({route, store, error}) {
    await store.dispatch('landing/fetchFranchise', {
      slug: route.params.landing,
    });
    const landing = store.getters['landing/franchise']
    if (!landing) {
      error({ statusCode: 404, message: 'Page not found' })
    }
  },
  computed: mapGetters({
    landing: 'landing/franchise'
  }),
};
