//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccountFranchisorMobileMenu from "@/components/account-franchisor/menu/AccountFranchisorMobileMenu";
import BaseButton from "@/components/buttons/BaseButton";
import VSelect from "@/components/form/VSelect";
import ArrowBack from "@/components/icons/ArrowBack";
import TableBudget from "@/components/tables/TableBudget";
import Form from "vform";
import Loading from "vue-loading-overlay";
import {mapGetters} from "vuex";
import storeMixin from "@/components/mixins/storeMixin";

export default {
  name:'AccountFranchisorBudgetPage',
  components: {
    BaseButton,
    TableBudget,
    VSelect,
    ArrowBack,
    AccountFranchisorMobileMenu,
    Loading,
  },
  mixins: [
    storeMixin
  ],
  async asyncData({ $axios }) {
    let industries = [];

    try {
      const {data} = await $axios.get('/api/v1/industry/all');
      industries = data;
    } catch (e) {
      console.log(e)
    }

    return {
      industries,
    }
  },
  data () {
    return {
      form: new Form({
        units: null,
        industry: '',
        industryId: null,
      }),
      options: {
        units: null,
        industry: [],
      },
      avgCost: 0,
      focusedInp:false,
      totalLeads: null,
      isLoading: true,
      checkSaved: false,
      activeMessage: false,
      activeSave: false,
      marketingSpend: '',
      conversionRate: '',
      month:[],
      items: [],
      itemsMobile:[],
      budgetFromDb: [],
      qualifiedLeads:[],
      salesPerMonth: [],
      monthlyPercent: [],
      monthlyProposed:[],
      industries: [],
      newItems: {
        month:[],
        sales: [],
        budget: [],
        leads : [],
        percent: [],
      },
      fields: [
        { key:'month', label:'' },
        { key:'percent', label:'Percent of annual Spend' },
        { key:'budget', label:'Monthly proposed budget, $' },
        { key:'leads', label:'Qualified leads' },
        { key:'sales', label:'Sales ' },
      ],
      fieldsMobile: [
        { key:'month', label:'Month' },
        { key:'leads', label:'Qualified leads' },
        { key:'budget', label:'Monthly proposed budget, $' },
        { key:'sales', label:'Sales ' },
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      franchise: 'business-v2/franchise'
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'options.units': function() {
      this.checkIsNumber();
      this.calculateData();
    },
    // eslint-disable-next-line func-names
    'form.industry': function () {
      this.getDefaultData();
      this.calculateData();
    },
    marketingSpend() {
      this.setItems();
    }
  },
  async mounted() {
    await this.getBudget();
    this.getDefaultData();
    this.calculateData();
    this.setMonthPercents();
    this.setItems();
    this.checkIsNumber();
    this.getActiveItem();
    if (this.industries) {
      this.options.industry = this.industries
      this.options.month = Array.from(Array(18).keys());
    }
  },
  methods: {
    checkUnitsChanged() {
      if (this.budgetFromDb) {
        this.activeMessage = Number(this.budgetFromDb.units) === Number(this.options.units) ?
          false : !!Number(this.options.units);
        this.activeSave    = this.activeMessage;
      } else {
        this.activeMessage = !!Number(this.options.units);
        this.activeSave    = this.activeMessage;
      }
    },
    checkIndustryChanged() {
      this.activeSave    = !!Number(this.options.units);
      this.activeMessage = this.activeSave;
    },
    showMenu() {
      const mobileMenu  = this.$refs.mobileMenu.classList;
      const mobileImage = this.$refs.mobileImage.classList;

      if(mobileMenu.contains('display-block')){
        mobileMenu.remove('display-block');
        mobileImage.remove('rotate-image');
      }else{
        mobileMenu.add('display-block')
        mobileImage.add('rotate-image');
      }
    },
    getActiveItem() {
      if(process.client) {
        const items = document.querySelectorAll('.menu-item-children__item');
        items.forEach((item) => {
          if(item.classList.contains('nuxt-link-exact-active')) this.activeItem = item.innerText
        });
      }
    },
    setMonthPercents() {
      /* First one for total and + 18 month  */
      this.newItems.month =  Array.from(Array(19).keys());
      this.newItems.percent = [0, 15, 10].concat(Array(10).fill(7.5)).concat(Array(6).fill(0));
    },
    setItems() {
      this.items = [];
      this.itemsMobile = [];
      for(let i = 0; i < this.newItems.month.length; i++) {
        this.items.push({
          'month'  : this.newItems.month[i],
          'percent': this.newItems.percent[i],
          'budget' : this.newItems.budget[i],
          'leads'  : this.newItems.leads[i],
          'sales'  : this.newItems.sales[i],
        });
        this.itemsMobile.push({
          'month'  : this.newItems.month[i],
          'leads'  : this.newItems.leads[i]  ? this.formatThousands(this.newItems.leads[i]) : '-',
          'budget' : this.newItems.budget[i] ? this.formatThousands(this.newItems.budget[i]): '-',
          'sales'  : this.newItems.sales[i]  ? this.formatThousands(this.newItems.sales[i]) : '-',
        });
      }
      this.itemsMobile[0].month = 'Total';
    },
    calculateData() {
      this.newItems.month =  Array.from(Array(19).keys());
      this.newItems.percent = ['-', 15, 10].concat(Array(10).fill(7.5)).concat(Array(6).fill(0));
      this.month =  Array.from(Array(19).keys())
      this.newItems.budget = [];
      this.newItems.leads  = [];
      this.newItems.sales  = [];

      for (let i =0; i < 19; i++) {

        /*  1 column is total  & we don't show %  default 100% */
        if (i === 0) {
          this.newItems.sales.push(0);
          this.newItems.leads.push(this.totalLeads);
          this.newItems.budget.push(this.marketingSpend);

        } else if (i === 1) {

          /* 2 column is 1month 15% of budget */
          this.newItems.leads.push(0);
          this.newItems.sales.push(0);
          this.newItems.budget.push(this.calculateMarketingSpend(this.marketingSpend, 15));

        } else if (i === 2) {

          /* 3 column is 2 month 10% of budget  */
          this.newItems.sales.push(0);
          this.newItems.budget.push(this.calculateMarketingSpend(this.marketingSpend, 10));
          this.newItems.leads.push(this.calculateMarketingSpend(this.marketingSpend, 15, this.avgCost));
        } else {

          /* From 2 to 12 months we spend 7.5% of the budget per month, but after 12 months we do not spend anything */
          if(i > 2 && i < 13 ) {
            this.newItems.budget.push(this.calculateMarketingSpend(this.marketingSpend, 7.5));
          } else {
            this.newItems.budget.push(this.calculateMarketingSpend(this.marketingSpend, 0))
          }

          if (i === 3) {
            this.newItems.leads.push(this.calculateMarketingSpend(this.marketingSpend,10,this.avgCost));
          } else {
            this.newItems.leads.push(this.calculateMarketingSpend(this.marketingSpend, 7.5, this.avgCost));
          }
          if (i >= 7) {
            this.newItems.sales.push(Math.round((this.conversionRate*(this.marketingSpend*7.5/100)/this.avgCost)/100));
          } else {
            this.newItems.sales.push(0);
          }
        }
      }
      /**
       * Sum of sales
       */
      this.newItems.sales[0] = this.options.units  ?
        this.newItems.sales.reduce((a, b) =>  { return a +b; }, 0)  : 0;
    },

    /**
     * Get calculate data for first industry in list by default
     */
    getDefaultData() {
      if(this.industries) {
        this.industries.map((item, ind) => {
          /* Get budget that we saved */
          if (!this.form.industry && this.checkSaved && !this.focusedInp) {
            this.isLoading = true;


            this.options.units = this.budgetFromDb.units;
            this.avgCost = this.budgetFromDb['cost-per-lead'];
            this.conversionRate = this.budgetFromDb['conversion-rate'];
            this.totalLeads = this.getTotalLeads(this.budgetFromDb.units,this.conversionRate );
            this.marketingSpend = this.getMarketingSpend(this.totalLeads, this.avgCost);
            this.form.units = this.budgetFromDb.units;
            this.form.industry = this.budgetFromDb.title
            this.form.industryId = this.budgetFromDb.industryId;

            this.isLoading = false;
          } else if (this.form.industry === item.value) {
            /* Get interactive calculating budget */
            this.isLoading = true;

            this.avgCost = Number(item['cost-per-lead']);
            this.conversionRate = Number(item['conversion-rate']);
            this.totalLeads = this.getTotalLeads(this.options.units,this.conversionRate)
            this.marketingSpend = this.getMarketingSpend(this.totalLeads, item['cost-per-lead']);
            this.form.industryId = item.id;
            this.form.units = this.options.units;

            this.isLoading = false;
          } else if (!this.form.industry && !this.checkSaved  && !this.focusedInp) {
            /* Get for default 1 unit and own industry if we didn't save any budget */
            if(this.franchise.industry === item.title) {
              this.isLoading = true;

              this.options.units = 1;
              this.avgCost = Number(item['cost-per-lead']);
              this.conversionRate = Number(item['conversion-rate']);
              this.totalLeads = this.getTotalLeads(this.options.units,this.conversionRate)
              this.marketingSpend = this.getMarketingSpend(this.totalLeads, item['cost-per-lead']);
              this.form.industry = item.title;
              this.form.units = this.options.units;

              this.isLoading = false;
            }
          }
        })
      }
    },

    /**
     * Save or update budget
     * @returns {Promise<void>}
     */
    async saveBudget() {
      if (this.activeSave) {
        this.fireModalMessage({ message: 'Budgetary estimation is successfully saved.' })
        this.activeSave = false;

        try {
          const data = await this.form.post('/api/v1/industry/add-budget');
          if (data) {
            await this.getBudget();
          }
        } catch (e) {
          console.log(e)
        }
      }
    },

    /**
     *  Get saved budget
     * @returns {Promise<void>}
     */
    async getBudget() {
      if (this.user.isFranchisor) {
        try {
          const { data } =  await this.$axios.get('/api/v1/industry/get-budget');
          this.budgetFromDb = data[0];
          this.checkSaved = !!data[0];
        } catch (e) {
          console.log(e)
        }
      }
    },

    inpFocus() {
      this.focusedInp = true;
    },

    async sendMessage() {
      const info = {
        'average-lead'    : `$${this.avgCost}`,
        'sales-goal'      : this.options.units,
        'average-rate'    : `${this.conversionRate}%`,
        'industry-name'   : this.form.industry,
        'brand-name'      : this.franchise.business_name,
        'francisor-name'  : this.user.full_name
      };
      this.items[0] = { ... this.items[0], ...info };
      if (this.activeMessage) {
        this.fireModalMessage({
          message: 'Your order is well received. We will contact you soon.'
        })
        this.activeSave = false;
        this.activeMessage = false;
        try {
          await this.$axios.post('/api/v1/industry/send-email', this.items);
          await this.form.post('/api/v1/industry/add-budget');
        } catch (e) {
          console.log(e)
        }
      }
    },

    getTotalLeads(units, conversion) {
      return  Math.round((Number(units)/conversion ) * 100);
    },

    getMarketingSpend(total, avg) {
      return Math.round(total * avg);
    },

    calculateMarketingSpend(data, percent, avg=1) {
      return Math.round((data * percent/100)/avg);
    },

    /**
     * check if input value is number
     */
    checkIsNumber() {
      if (this.options.units) {
        const array = Number.isInteger(this.options.units) ?
          this.options.units.toString().split(''):this.options.units.split('');
        const filtered = array.filter((item) => !isNaN(Number(item) ));
        this.options.units = null;
        this.options.units = filtered.join('');
      }
      this.getDefaultData();
    },

    /**
     * Add comma if data more than 999
     * @param number
     * @returns {string}
     */
    formatThousands(number) {
      return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): null;
    },

  }
}
