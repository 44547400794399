//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import MenuListTopFranchiseTile from '@/components/menu/MenuListTopFranchiseTile';
import Loader from '@/components/Loader';
import ArrowIcon from '@/assets/icons/arrows/ArrowIcon.svg?inline';
import CardTopFranchise from '@/components/cards/CardTopFranchise';

export default {
  name: 'TopFranchiseIndexPage',
  components: {
    CardTopFranchise,
    Loader,
    MenuListTopFranchiseTile,
    ArrowIcon,
  },
  data: () => ({
    tableView: false,
    scrollAfterChangePage: false,
  }),
  computed: {
    ...mapGetters({
      busy: 'top-franchise/busy',
      items: 'top-franchise/topListNotPinnedArr',
    }),
  },
  mounted() {
    this.fetchTopListArrNotPinned();
  },
  methods: {
    ...mapActions({
      fetchTopListArrNotPinned: 'top-franchise/fetchTopListArrNotPinned',
    }),
    handlerChangePage(page) {
      this.scrollAfterChangePage = true;

      this.$store.commit('business/SET_MAIN_FILTER_PAGINATION', {
        current_page: page,
      });
      this.fetchBusiness();
    },
  },
  head() {
    const title = 'Top Franchises To Buy & Own 2020';
    const description =
      'Explore our curated list of the top franchises by industry to buy and own. We have got franchises from a low initial investment yet profitable. Start today!';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        },
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: description,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
      ],
    };
  },
};
