//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import {mapGetters} from "vuex"
import 'vue-loading-overlay/dist/vue-loading.css'
import hubspotMixin from "@/components/mixins/hubspotMixin"
import googleAnalyticsMixin from "@/components/mixins/googleAnalyticsMixin"
import styleImportMixin from "@/components/mixins/styleImportMixin"
import VInputV3 from "@/components/form/VInputV3"
import BaseButton from "@/components/buttons/BaseButton"
import ModalText from "@/components/modals/ModalText"
import BaseModal from "~/components/modals/BaseModal"

export default {
  components: {
    BaseButton,
    VInputV3,
    ModalText,
    BaseModal,
  },
  layout: "landing",
  mixins: [
    hubspotMixin,
    googleAnalyticsMixin,
    styleImportMixin,
  ],
  async asyncData({query, $axios, route, error}) {
    const landingMeta = {
      title: null
    }
    let landingBody = {
      navbar: null,
      navbar_menu: null,
      gjs_html: null,
      gjs_css: null
    }
    let landingData = {}
    let navbarFontStyles = {}
    const params = {
      slug: route.params.landing,
      page: route.params.page,
    }
    let subPageTitle
    // get landing template
    try {
      const {data} = await $axios.get(
        `/api/v1/gjs-template/${params.slug}/landing`, {
          params
        }
      )
      landingData = data
      landingBody = data.gjs
      landingBody.gjs_css = `<style type="text/css">${landingBody.gjs_css}</style>`
    } catch (e) {
      error({statusCode: 404, message: 'Page not found'})
      console.log(e)
      return false
    }
    // get navbar menu
    let navBarLinks
    try {
      const {data} = await $axios.get(
        `/api/v1/gjs-template/${landingData.business_id}/menu`, {
          params
        })
      navBarLinks = data
    } catch (e) {
      console.log(e)
    }
    landingBody.navbar_menu = ''
    Object.entries(navBarLinks).forEach(([page, navBarLink]) => {
      let anchor = ''
      let subPage = ''
      let link = `/${params.slug}`
      if (navBarLink.type === 'anchor') {
        const pageWithAnchor = navBarLink.url.split('#')
        if (pageWithAnchor[0] !== 'homepage' && navBarLinks[pageWithAnchor[0]].type === 'page') {
          subPage = navBarLinks[pageWithAnchor[0]].url
        }
        anchor = pageWithAnchor[1]
      } else if (navBarLink.type === 'page') {
        subPage = navBarLink.url
      }
      if (subPage) {
        link = `${link}/${subPage}`
      }
      if (anchor) {
        link = `${link}#${anchor}`
      }
      landingBody.navbar_menu += `<a href="${link}">${navBarLink.title}</a>`
      if (subPage === params.page) {
        subPageTitle = navBarLink.title
      }
    })
    landingBody.navbar = `
            <header class="header">
              <div class="nav-container">
                <div class="wrapper">
                  <a href="/${params.slug}" class="logo">
                    <img src="${landingData.logo_landing.landing}" id="ine8">
                  </a>
                  <div class="nav" onclick="this.classList.toggle('active')">
        <svg height="23px" class="page-burger-close" id="page-burger-close" viewBox="0 0 329.26933 329" width="23px" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" style="fill:#000;"/></svg>
        <svg height="32px" class="page-burger" id="page-burger" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" style="fill:#000;"/></svg>
        <div class="nav-links">${landingBody.navbar_menu}</div>
                  </div>
                  <div class="phone">
                    <a href="tel:${landingData.landing_phone}" id="iq1c">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.445 17.827c-3.684 1.684-9.401-9.43-5.8-11.308l1.053-.519 1.746 3.409-1.042.513c-1.095.587 1.185 5.04 2.305 4.497l1.032-.505 1.76 3.397-1.054.516z"/></svg>
                        ${landingData.landing_phone}
                    </a>
                  </div>
                </div>
              </div>
            </header>`
    if (landingData.landing_navbar_font) {
      Object.assign(
        navbarFontStyles,
        {'font-family': landingData.landing_navbar_font}
      )
    }
    if (landingData.landing_navbar_font_style) {
      Object.assign(
        navbarFontStyles,
        {'font-style': landingData.landing_navbar_font_style}
      )
    }
    if (landingData.landing_navbar_font_weight) {
      Object.assign(
        navbarFontStyles,
        {'font-weight': landingData.landing_navbar_font_weight}
      )
    }
    if (navbarFontStyles) {
      navbarFontStyles = Object.entries(navbarFontStyles).map(([k, v]) => `${k}:${v}`).join(';')
      landingBody.navbar += `
          <style type="text/css">
            .header .nav-container .nav a {
              ${navbarFontStyles}
            }
            .header .nav-container .phone a {
              ${navbarFontStyles}
            }
          </style>`
    }
    // set landing meta
    landingMeta.title = landingData.business_name
    if (subPageTitle) {
      landingMeta.title += ` | ${subPageTitle}`
    }
    return {
      landingMeta,
      landingBody,
      landingData,
      params
    }
  },
  mounted() {
    this.reportEventListen()
    this.hubspotEventListen()
    this.linkBtnEvent()
    this.configureStyles()
    this.goToTheAchor()
    this.initAccordeon()
  },
  head() {
    const title = `${this.landingMeta.title} page`
    const descr = `${this.landingMeta.title} franchise page`
    const keywords = ''
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ],
    }
  },
  validate({params}) {
    return params.landing
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/check'
  }),
  data() {
    return {
      reportSent: false,
      sentModalShow: false,
      showModalContactForm: false,
      sentModalText: '',
      businessName: 'Franchise123',
      franchise: {
        business_name: '',
        logo: {
          mini: '',
          small: '',
          medium: '',
          large: '',
          landing: ''
        },
        landing_phone: '',
      },
      formMap: {
        first_name: 0,
        last_name: 1,
        phone: 2,
        email: 3,
        agree: 4,
      },
      formSend: new Form({
        franchise_id: null,
        business_name: null,
        page_url: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        agree: null,
        hubspotutk: null
      }),
      isLoading: true,
      params: {
        franchiseId: 0,
        slug: '',
        page: ''
      }
    }
  },
  methods: {
    downloadReport() {
      let url = ''
      const id = this.landingData.business_id

      if (id === 3007) { // the barre code
        url = 'https://storage.googleapis.com/internal-shared-fdp-production/business_files/files/AboutTheBarreCode.pdf'
      }

      if (id === 3810) { // splash & dash
        url = 'https://storage.googleapis.com/internal-shared-fdp-production/business_files/files/Splash%20and%20Dash%20Information%20Packet.pdf'
      }

      if (id === 2867) { // cityrow
        url = 'https://storage.googleapis.com/internal-shared-fdp-production/business_files/files/CITYROW%20Lookbook.pdf'
      }

      if (id === 3547) { // garage kings
        url = 'https://storage.googleapis.com/internal-shared-fdp-production/business_files/files/GK%20Franchise%20Insight%20Report%202021%20-%20Franchise%20Accelerators.pdf'
      }

      if (id === 3988) { // title boxing
        url = 'https://storage.googleapis.com/internal-shared-fdp-production/business_files/files/TitleBoxingClub_ebook.pdf'
      }

      if (url) {
        window.open(url, '_blank')
      } else {
        this.showModalContactForm = false
      }

    },
    async hubspotEventSent(data, report = false) {
      if (!report) {
        this.collectFrontFormData(data)
      }
      const result = await this.hsLandingForm(
        this.landingData.business_id,
        this.landingData.business_name,
        this.formSend.first_name,
        this.formSend.last_name,
        this.formSend.email,
        this.formSend.phone,
      )
      this.sentModalText = result.msg

      if (result.ok) {

        if (report) {
          this.reportSent = true
        } else {
          this.sentModalShow = true
        }

        await this.gaSendLandingForm()
      } else {
        this.sentModalShow = true
      }

    },
    collectFrontFormData(data) {
      Object.entries(this.formMap).map((filedName, fieldId) => {
        this.formSend[filedName[0]] = data[fieldId].value
      })
    },
    reportEventListen() {
      const btn = document.getElementsByClassName('pages-button')[0]
      if (btn) {
        btn.addEventListener("click", (e) => {
          e.preventDefault()
          this.showModalContactForm = true
        }, false)
      }
    },
    hubspotEventListen() {
      if (process.client) {
        const formFunc = this.hubspotEventSent
        const forms = document.getElementsByTagName('form')
        for (let i = 0; i < forms.length; i++) {
          forms[i].addEventListener('submit', function (event) {
            formFunc(forms[i])
            event.preventDefault()
          })
        }
      }
    },
    linkBtnEvent() {
      if (process.client) {
        const classname = document.getElementsByClassName('btn')
        for (let i = 0; i < classname.length; i++) {
          classname[i].addEventListener('click', function () {
            window.open('https://calendly.com/susan-617', '_blank')
          })
        }
      }
    },
    goToTheAchor() {
      if (process.client) {
        const url = document.location.href
        if (url.indexOf("#")) {
          const hash = url.substring(url.indexOf("#") + 1)
          if (hash !== null) {
            const anchor = document.getElementById(hash)
            if (anchor !== null) {
              window.scrollTo(0, 0)
              window.scrollTo(0, anchor.getBoundingClientRect().y)
            }
          }
        }
      }
    },
    configureStyles() {
      if (process.client && this.params.page !== 'insight') {
        // disable conflict styles
        const sheets = document.querySelectorAll("link[href^='/_nuxt/app.']")
        for (let i = 0; i < sheets.length; i++) {
          sheets[i].disabled = true
        }
        this.importStyle(`/gjs/css/fonts.css`)
      }
    },
    initSlider() {
      const initMySwiper = function initMySwiper() {
        if (!document.querySelector('.swiper-container')) {
          return false
        }
        const mySwiper = new Swiper('.swiper-container', {
            loop: true,
            // simulateTouch: false,
            pagination: {
              el: '.swiper-pagination'
            }
          }
        )
        const mySwiperEl = document.querySelector('.swiper-container').swiper
        const prev = document.getElementById('swiper-prev-btn')
        prev.onclick = function () {
          mySwiperEl.slidePrev()
        }
        const next = document.getElementById('swiper-next-btn')
        next.onclick = function () {
          mySwiperEl.slideNext()
        }
      }
      if (typeof mySwiper === 'undefined') {
        const scriptJS = document.createElement('script')
        scriptJS.onload = initMySwiper
        scriptJS.src = 'https://unpkg.com/swiper/swiper-bundle.min.js'
        document.body.appendChild(scriptJS)
      }
    },
    initAccordeon() {
      const labelsFor = document.getElementsByClassName('for-radio')
      for (let i = 0; i < labelsFor.length; i++) {
        labelsFor[i].onclick = function (e) {
          const tabID = labelsFor[i].getAttribute('for')
          const tab = document.getElementById(tabID)
          if (tab.checked === true) {
            tab.checked = false
            e.preventDefault()
          }
        }
      }
    },
    getCookie(name) {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
      if (match) return match[2]
    },
  }
}
