import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=0a95557d&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=0a95557d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a95557d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatContactAvatar: require('E:/all-projects/franchise-digital-platform-spa/components/chat/ChatContactAvatar.vue').default,ChatArea: require('E:/all-projects/franchise-digital-platform-spa/components/chat/ChatArea.vue').default})
