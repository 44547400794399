//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters,
} from 'vuex'
import Form from 'vform'
import BaseModal from '~/components/modals/BaseModal'
import VInput from '~/components/form/VInput'
import VPasswordInput from '~/components/form/VPasswordInput'

export default {
  components: {
    BaseModal,
    VInput,
    VPasswordInput,
  },
  metaInfo() {
    return { title: this.$t('reset_password') }
  },
  data: () => ({
    modalShow: true,
    resending: false,
    changed: false,
    status: '',
    message: '',
    form: new Form({
      token: '',
      email: '',
      password: '',
      password_confirmation: '',
    }),
    loading: true,
  }),
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/check',
  }),
  async created() {
    this.form.email = this.$route.query.email
    this.form.token = this.$route.query.token
    await this.checkResetToken()
  },
  methods: {
    async checkResetToken() {
      try {
        await this.form.post('api/password/check-token')
      } catch (e) {
        this.status = 'fail'
        this.message = e.response.data.message
        console.log(e)
      }
      this.loading = false
    },
    async reset() {
      if (this.user !== null) {
        await this.$store.dispatch('auth/logout')
      }

      try {
        const { data } = await this.form.post('/api/password/reset')

        this.setAlertFields(data.message, data.status)
        this.changed = true
        await this.login()
        this.closeModal()
      } catch (e) {
        this.changed = false
        if (e.hasOwnProperty('response')) {
          if (e.response.status === 400 && e.response.data.status === 'fail') {
            this.setAlertFields(e.response.data.message, e.response.data.status)
          } else if (e.response.status !== 422) {
            this.closeModal()
          }
        }
      }
    },
    async requestReset() {
      try {
        this.resending = true
        const { data } = await this.form.post('/api/password/email')

        this.setAlertFields(data.message, data.status)
        this.form.reset()
        this.$refs.resetForm.reset()
        this.resending = false
      } catch (e) {
        if (e.hasOwnProperty('response')) {
          if (e.response.status === 400 && e.response.data.status === 'fail') {
            this.setAlertFields(e.response.data.message, e.response.data.status)
          } else if (e.response.status !== 422) {
            this.closeModal()
          }
        }
      }
    },
    async login() {
      // Submit the form.
      try {
        this.setAlertFields()

        const form = new Form({
          email: this.form.email,
          password: this.form.password,
          remember: false,
          busy: false,
        })

        const { data } = await form.post('/api/login')

        // Save the token.
        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires
        })

        // Fetch the user.
        await this.$store.dispatch('auth/fetchUser')

        let to = '/'
        if (this.user.isFranchisee) {
          to = '/account/my-matches'
        } else if (this.user.isFranchisor) {
          to = '/account-franchisor'
        }
        window.location.href = to
      } catch (e) {
        if (e.hasOwnProperty('response')) {
          if (
            e.response.status === 400 &&
            e.response.data.status === 'fail' &&
            e.response.data.message
          ) {
            this.form.reset()
            this.$refs.loginForm.reset()
            this.setAlertFields(e.response.data.message, e.response.data.status)
          } else if (e.response.status !== 422) {
            this.closeModal()
          }
        }
      }
    },
    closeModal(to = '/') {
      this.modalShow = false
      this.$router.push(to)
    },
    setAlertFields(message = '', status = '') {
      this.message = message
      this.status = status
    },
  },
}
