//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import ContactList from "@/components/lists/ContactList"
import DocumentList from "@/components/lists/DocumentList"
import LinkButton from "@/components/buttons/LinkButton"
import ArrowBack from "@/components/icons/ArrowBack"
import ModalViewFranchisee from "@/components/modals/franchisor/ModalViewFranchisee"
import ArrowLeft from '@/assets/images/svg/arrow-left-green.svg?inline'
import ModalAddDocument from '@/components/modals/ModalAddDocument'
import storeMixin from "@/components/mixins/storeMixin"
import PaginationDefault from "@/components/paginations/PaginationDefault"
import Loader from "@/components/Loader"
import FindChat from "@/components/account-franchisor/inputs/FindChat"

export default {
  name: 'PageStepIndex',
  components: {
    FindChat,
    ModalViewFranchisee,
    ArrowBack,
    LinkButton,
    DocumentList,
    ContactList,
    Loader,
    ArrowLeft,
    ModalAddDocument,
    PaginationDefault,
  },
  mixins: [
    storeMixin
  ],
  data() {
    return {
      documentList: [],
      documentUploadLoading: false,
      mobileTabs: [
        {
          title: 'Messages',
          id: 'chat__contacts',
        },
        {
          title: 'Documents',
          id: 'chat__documents',
        },
      ],
      activeMobileTabIndex: 0,
      showModalNewDocument: false,
      findChatName: '',
      debounceChatFind: null,
    }
  },
  computed: {
    ...mapGetters({
      contactList: 'chat/contactList',
      contactListLoading: 'chat/contactListLoading',
      contactListPagination: 'chat/contactListPagination',
      chosenContact: 'chat/chosenContact',
      chosenContactIndex: 'chat/chosenContactIndex',
      businessStep: 'franchisor-steps/businessStep',
      user: 'auth/user',
      paymentPermissions: 'payment/permissions'
    }),
    activeTabName() {
      return this.mobileTabs[this.activeMobileTabIndex].title.toLowerCase();
    },
    isActiveMessages() {
      return this.activeMobileTabIndex === 0;
    },
    isActiveDocuments() {
      return this.activeMobileTabIndex === 1;
    },
  },
  watch: {
    findChatName() {
      if (this.debounceChatFind !== null)
        clearTimeout(this.debounceChatFind)

      this.debounceChatFind = setTimeout(() => {
        this.$store.commit(`chat/SET_CHAT_CONTACT_LIST_PAGINATION`, {
          current_page: 1,
        });
        this.updateChatContactList()
        clearTimeout(this.debounceChatFind)
        this.debounceChatFind = null
      }, 1000)

    },
  },
  async mounted() {
    this.$store.commit(`chat/SET_CHAT_CONTACT_LIST_PAGINATION`, {
      current_page: 1,
    });
    const businessStepPayload = {
      slug: this.$route.params.step,
      franchiseId: this.$route.params.franchiseId
    }

    await this.$store.dispatch('franchisor-steps/fetchBusinessStep', businessStepPayload)
    await this.updateChatContactList()
    await this.fetchDocuments();
  },
  methods: {
    async updateChatContactList() {
      await this.$store.dispatch('chat/fetchContactList', {
        businessStepId: this.businessStep.business_step_id,
        fullName: this.findChatName
      })
    },
    /**
     * Close chat window & clear chat data
     */
    closeChatWindow() {
      this.$store.commit(`chat/SET_CHOSEN_CHAT_CONTACT`, -1);
      this.$store.commit(`chat/SET_CHAT_CONTACT_MESSAGES`, []);
      this.$router.push(
        this.buildAccountFranchisorUri(`/account-franchisor/${this.$route.params.franchiseId}/steps/${this.$route.params.step}`)
      );
    },
    /**
     * Set chosen contact
     * @param {number} index - index of chosen contact
     */
    handleContactChose(index) {
      if (this.user.isFranchisor && !this.paymentPermissions.chatDialog.access) {
        this.fireModalMessage({message: this.paymentPermissions.chatDialog.message})
        return
      }

      const contact = this.contactList[index]
      const chatLink = this.buildAccountFranchisorUri(
        `/account-franchisor/${this.$route.params.franchiseId}/steps/${this.$route.params.step}/chat/${contact.slug}`
      );
      this.$router.push(chatLink);
    },
    /**
     * Change pagination user list
     */
    async contactListPaginationChange(pageNumber) {
      this.$store.commit(`chat/SET_CHAT_CONTACT_LIST_PAGINATION`, {
        current_page: pageNumber,
      })
      await this.updateChatContactList()
    },
    /**
     * Click add new document handler
     */
    handleClickAddNew() {
      if (this.user.isFranchisor && !this.paymentPermissions.addDocumentToStep) {
        this.fireModalMessage({message: this.paymentPermissions.addDocumentToStep.message})
        return
      }

      this.showModalNewDocument = !this.showModalNewDocument
    },
    /**
     * Show popup selection files
     */
    handleChoseDocument() {
      this.$refs.documentFile.click();
    },
    /**
     * Fetch business documents by business step id
     */
    async fetchDocuments() {
      this.documentUploadLoading = true;
      const {data} = await this.$axios.get(
        `/api/v1/business-step-docs/${this.businessStep.business_step_id}`
      );
      this.documentList = data
      this.documentUploadLoading = false;
    },

    /**
     * Adding document event
     * @param {Array} documents uploaded document
     */
    onDocumentAdd() {
      this.showModalNewDocument = false
      this.fetchDocuments()
    },
  },
};
