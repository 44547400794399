//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import ContactInfo from "@/components/account-franchisor/contacts/ContactInfo"
import ContactActionsMenu from "@/components/account-franchisor/contacts/ContactActionsMenu"
import ContactActionsActivity from "@/components/account-franchisor/contacts/actions/ContactActionsActivity"
import ContactActionsCalls from "@/components/account-franchisor/contacts/actions/ContactActionsCalls"
import ContactActionsEmails from "@/components/account-franchisor/contacts/actions/ContactActionsEmails"
import ContactActionsMeetings from "@/components/account-franchisor/contacts/actions/ContactActionsMeetings"
import ContactActionsNotes from "@/components/account-franchisor/contacts/actions/ContactActionsNotes"
import ContactActionsTasks from "@/components/account-franchisor/contacts/actions/ContactActionsTasks"
import ContactActionsHistory from "@/components/account-franchisor/contacts/actions/ContactActionsHistory"

import {
  CONTACT_ACTIONS_MENU,
} from "~/store/constants"
import storeMixin from "~/components/mixins/storeMixin"
import pagesMixin from "~/components/mixins/pagesMixin"

export default {
  name: 'FranchisorAccountContactPage',
  components: {
    ContactInfo,
    ContactActionsMenu,
    ContactActionsActivity,
    ContactActionsCalls,
    ContactActionsEmails,
    ContactActionsMeetings,
    ContactActionsNotes,
    ContactActionsTasks,
    ContactActionsHistory,
  },
  mixins: [
    storeMixin,
    pagesMixin,
  ],
  data() {
    return {
      menu: CONTACT_ACTIONS_MENU,
      activeMenuId: CONTACT_ACTIONS_MENU.tasks.id,
    }
  },
  computed: {
    ...mapGetters({
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions',
    }),
    franchiseeId() {
      return +this.$route.params.contact ?? 0
    }
  },
  async mounted() {
    await this.checkPermissions()
    if (this.pmIsFranchisorContactTaskTab()) {
      this.activeMenuId = CONTACT_ACTIONS_MENU.tasks.id
    }
  },
  methods: {
    async checkPermissions() {
      const permissionType = this.paymentPermissions.CRM
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({ message })
        await this.$router.push('/account-franchisor/dashboard/')
      }
    },
    openItem(id) {
      this.activeMenuId = id
    },
  }
}
