//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchIcon from "@/components/icons/SearchIcon";
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "CompanyListPage",
  components: {Loader, SearchIcon},
  async asyncData({store, query, error}) {
    error({ statusCode: 404, message: 'Page not found' })
    return false

    const searchValue = query.search ?? '';
    const currentPage = query.page ?? 1;
    const selectSymbol = query.letter ?? '';

    store.commit("company/CHANGE_FILTER_ALPHABET_ITEM", selectSymbol);
    store.commit("company/SET_SEARCH_VALUE",searchValue);
    store.commit("company/SET_MAIN_FILTER_PAGINATION", {current_page: currentPage, per_page: 20});
    store.commit("company/SET_SORTING", {by: 'business_name', desc: false});
    store.commit("company/SET_IGNORE_PUBLICATION_STATUS", true);
    await store.dispatch('company/getAlphabetFilter', true);
    await store.dispatch('company/getCompanyArr', true);

    return {searchValue, currentPage, selectSymbol}
  },
  head() {
    const title = `Company Pages`;
    const descr = `Company pages`;
    const keywords = '';

    return {
      title: title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ],
    };
  },
  data() {
    return {
      selectSymbol: '',
      searchValue: '',
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters({
      companyArr: "company/dataArr",
      pagination: "company/pagination",
      fetchData: "company/busy",
      search: "company/search",
      filter: "company/filter",
      alphabetFilterArr: "company/alphabetFilterArr"
    }),
    titleCollapseButton() {
      return this.collapseIsOpen ? 'less about company' : 'more about company'
    },
    getSelectSymbol(){
      const filterArr = this.alphabetFilterArr;
      if (!filterArr.length)
        return '';

      const selectedSymbol = filterArr.filter(item => item.active);

      return selectedSymbol.length && selectedSymbol[0].letter === 'All' ? '' : selectedSymbol[0].letter;
    },
    numberOfPages(){
      return Math.ceil(this.pagination.total/this.pagination.per_page)
    }
  },
  methods: {
    async changePageNumber(pageNum) {
      let url = `/company-pages?page=${pageNum}`;

      if (this.selectSymbol !== '' && this.searchValue === '')
        url += `&letter=${this.selectSymbol}`

      if (this.searchValue !== '')
        url += `&search=${this.searchValue}`

      await this.$router.push(url);
      await this.$store.commit("company/SET_MAIN_FILTER_PAGINATION", {current_page: pageNum});
      await this.updateData();
    },
    async setActiveLetterInFilter(letter){
      const setValue = letter.toLowerCase() === 'all'?'':letter;
      const url = setValue ===''? '/company-pages' :`/company-pages?letter=${setValue}`

      await this.$router.push(url);
      await this.$store.commit("company/SET_SEARCH_VALUE", '');
      this.searchValue = ''
      await this.$store.commit("company/CHANGE_FILTER_ALPHABET_ITEM", setValue);
      await this.updateData();
    },
    async changeSearchInput(){
      const url = this.searchValue === ''?'/company-pages':`/company-pages?search=${this.searchValue}`;

      await this.$router.push(url);
      await this.$store.commit("company/SET_SEARCH_VALUE", this.searchValue);
      await this.updateData();
    },
    async updateData(){
      await this.$store.dispatch('company/getAlphabetFilter', true);

      await this.$store.dispatch('company/getCompanyArr', true);
    }
  }

}
