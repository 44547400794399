//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import Form from "vform";
import { LANDING_FONT_LIST, USER_TYPE_MASTER_ADMIN } from "~/store/constants";
import getSlide from "~/components/mixins/slideTplMixin.js";
import axios from "axios";

export default {
  layout: "editor",
  mixins: [getSlide],
  head() {
    return {
      script: [
        {
          src: "/gjs/js/grapes.min.js"
        },
        {
          src: "/gjs/js/grapesjs-preset-webpage.min.js"
        }
      ],
      link: [
        {
          rel: "stylesheet",
          href: "/gjs/css/grapes.min.css"
        },
        {
          rel: "stylesheet",
          href: "/gjs/css/grapesjs-preset-webpage.min.css"
        }
      ]
    };
  },
  async asyncData({ query, $axios, route, error }) {
    let pages = []

    try {
      const { data } = await $axios.get(
        `/api/v1/gjs-template/${query.businessId}/${query.versionId}`
      );
      pages = data;
    } catch (e) {
      error({
        statusCode: e.response.status,
        message: e.response.message
      })
    }

    return {
      pages
    }
  },
  validate({ query }) {
    return query.businessId && query.versionId
  },
  data() {
    return {
      userTypeMasterAdmin: USER_TYPE_MASTER_ADMIN,
      hasFullAccess: false,
      pageIndex: "homepage",
      pageUrl: "homepage#top",
      pages: {
        homepage: {
          type: "page"
        }
      },
      editor: null,
      saveAlert: false,
      businessId: null,
      versionId: null,
      form: new Form({
        franchise_id: 0,
        franchise_url: null,
        event_type: "created_landing"
      }),
      grapesjsAssets: [],
      customFonts: LANDING_FONT_LIST
    };
  },
  computed: {
    ...mapGetters({
      token: "auth/token",
      user: "auth/user",
      paymentPermissions: 'payment/permissions'
    })
  },
  async mounted() {
    if (process.client) {
      this.form.franchise_id = this.$route.query.businessId;
      this.form.version_id = this.$route.query.versionId;
      this.$axios.defaults.baseURL + this.$route.fullPath;
      this.form.hubspotutk = this.getCookie("hubspotutk");

      this.checkAccess()
      await this.getAssets();
      this.initGrapes(this.form);
      this.updateEditorPage();
    }
  },
  methods: {
    ...mapActions({
      fetchPayment: 'payment/fetchPayment',
    }),
    removeCurrentSlide () {
      if(confirm(`Do you really want to delete current slide?`)) {
        let iframe = document.getElementsByClassName('gjs-frame')[0];
        let currentSliderEl = iframe.contentWindow.document.getElementsByClassName('swiper-slide-active');
        if (currentSliderEl[0] === undefined) { return false; }

        let currentSlider = currentSliderEl[0].id;
        let sliderCount = iframe.contentWindow.document.getElementsByClassName('swiper-slide');

        if (sliderCount.length === 1) {
          alert('Sorry, but you can\'t delete the last slide');
          return false;
        }

        let style = this.editor.getCss();
        let html =  this.editor.getHtml();

        let page = document.createElement('div');
        page.innerHTML = html;
        if (page.getElementsByClassName(currentSlider)[0] !== undefined) page.getElementsByClassName(currentSlider)[0].remove();

        this.editor.setComponents(page.innerHTML);
        this.editor.setStyle(style);
      }
    },
    addNewSlide () {
      if(confirm(`Do you really want to add new slide?`)) {
        let iframe = document.getElementsByClassName('gjs-frame')[0];
        let currentSlider = iframe.contentWindow.document.getElementsByClassName('swiper-slide-active')[0].id;
        let sliderCount = iframe.contentWindow.document.getElementsByClassName('swiper-slide');

        if (sliderCount.length === 10) {
          alert('Sorry, but you can\'t you cant add more than 10 slide');
          return false;
        }

        let style = this.editor.getCss();
        let html = this.editor.getHtml();

        let page = document.createElement('div');
        page.innerHTML = html;

        let slide = this.getSlide(sliderCount.length + 1);
        page.getElementsByClassName('swiper-wrapper')[0].innerHTML += slide;

        this.editor.setComponents(page.innerHTML);
        this.editor.setStyle(style);
      }
    },
    async checkAccess() {
      let hasFullAccess = true

      if (!this.user.isMasterAdmin) {
        this.fetchPayment()
        const response = await this.$axios.get(`/api/v1/user-franchise`);
        hasFullAccess = response.data.user_approved && this.paymentPermissions.editPages.show
      }

      this.hasFullAccess = hasFullAccess
    },
    async updateEditorPage() {
      this.editor.setComponents(this.pages["homepage"].gjs_html);
      this.editor.setStyle(this.pages["homepage"].gjs_css);

      if (this.user.user_type !== USER_TYPE_MASTER_ADMIN) {
        this.fixDom();
      }
    },
    async resetPage() {
      if(confirm(`Do you really want to reset current page?\nIt will cancel all your updates`)) {
        try {
          const baseUrl = this.$axios.defaults.baseURL;
          this.businessId = this.$route.query.businessId;
          let config = {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          };
          let urlReStore =
            `${baseUrl}/api/v1/gjs-template/${this.pageIndex}/${this.businessId}/reset`;
          const { data } = await axios.post(urlReStore, null, config);

          this.editor.setComponents(data.gjs_html);
          this.editor.setStyle(data.gjs_css);
        } catch (e) {
          console.log(e);
        }
      }
    },
    fixDom () {
      // fix dom elements (restrict delete/move)
      // const getAllComponents = (model, result = []) => {
      //   result.push(model);
      //   model.components().each(mod => getAllComponents(mod, result))
      //   return result;
      // }
      // const domElements = getAllComponents(this.editor.DomComponents.getWrapper());
      // domElements.forEach(function(e) {
      //   e.set('removable', false);
      //   e.set('draggable', false);
      //   // e.set('copyable', false);
      // });
    },
    async savePages() {
      let headers = {
        Authorization: `Bearer ${this.token}`
      };
      await axios.post(this.getUrl(), this.pages, {
        headers: headers
      });
    },
    getCookie(name) {
      let match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) return match[2];
    },
    saveCurrentState() {
      let currentIndex = this.pageIndex;
      if (currentIndex) {
        this.pages[currentIndex].gjs_html = this.editor.getHtml();
        this.pages[currentIndex].gjs_css = this.editor.getCss();
      }
    },
    selectGrapesPage(pageName) {
      let page = this.pages[pageName]
      this.pageUrl = page.url

      let anchor = ''
      if (page.type === 'anchor') {
        let pageWithAnchor = this.pageUrl.split('#')
        page = this.pages[pageWithAnchor[0]]
        anchor = pageWithAnchor[1]
      }

      if (pageName !== this.pageIndex) {
        this.saveCurrentState();
        this.editor.setComponents(page.gjs_html);
        this.editor.setStyle(page.gjs_css);
        this.pageIndex = pageName;

        if (this.user.user_type !== USER_TYPE_MASTER_ADMIN) {
          this.fixDom();
        }
      }

      if (anchor) {
        this.editor.Canvas.scrollTo(this.editor.DomComponents.getWrapper().find('#top')[0]);
        this.editor.Canvas.scrollTo(this.editor.DomComponents.getWrapper().find('#' + anchor)[0]);
      } else {
        this.editor.Canvas.scrollTo(this.editor.DomComponents.getWrapper());
      }
    },
    getUrl() {
      const baseUrl = this.$axios.defaults.baseURL;
      this.businessId = this.$route.query.businessId;
      this.versionId = this.$route.query.versionId;

      return `${baseUrl}/api/v1/gjs-template/${this.businessId}/${this.versionId}`;
    },
    switchPageStatus () {
      this.pages[this.pageIndex].is_active = this.pages[this.pageIndex].is_active ? 0 : 1;
      this.savePages();
    },
    initGrapes(form) {
      let vue = this;

      this.editor = grapesjs.init({
        container: "#gjs",
        allowScripts: true,
        showOffsets: true,
        fromElement: true,
        noticeOnUnload: false,
        storageManager: {
          autosave: false,
          // id: "gjs_",
          // type: "remote",
          // autosave: false,
          // urlLoad: `${baseUrl}/api/v1/business/get-gjs-template/${this.businessId}`,
          // urlStore: `${baseUrl}/api/v1/business/set-gjs-template/${this.businessId}`,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          credentials: "omit"
          // autoload: true,
          // storeStyles: 0,
          // storeComponents: 0,
          // storeAssets: 0,
          // onComplete(jqXHR, status) {
          // },
        },
        assetManager: {
          assets: this.grapesjsAssets,
          // Upload endpoint, set `false` to disable upload, default `false`
          upload: `${this.$axios.defaults.baseURL}/api/v1/gjs/upload-assets`,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          credentials: "omit",
          // The name used in POST to pass uploaded files, default: `'files'`
          uploadName: "files"
        },
        plugins: ["gjs-preset-webpage"],
        pluginsOpts: {
          "gjs-preset-webpage": {
            blocks: [],
            modalImportTitle: "Import Template",
            modalImportLabel:
              '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
            modalImportContent: function(editor) {
              return (
                editor.getHtml() + "<style>" + editor.getCss() + "</style>"
              );
            }
          }
        },
        canvas: {
          styles: [
            "gjs/css/fonts.css",
            "https://unpkg.com/swiper/swiper-bundle.min.css"
          ],
          scripts: [
            "gjs/js/main.js",
            "https://unpkg.com/swiper/swiper-bundle.min.js"
          ]
        },
        canvasCss: `
          .gjs-selected {
              background-color: #3b97e34D;
          }
          .phone,
          a[href^='tel:'] {
            pointer-events: none;
          }
          `
      });

      // Init GrapesJS events
      this.initGrapesJsEvents();

      this.editor.BlockManager.add('init-slider', {
        label: 'Init slider',
        attributes: {class: 'swiper-button-next'},
        content: {
          script: function () {
            let mySwiper = new Swiper('.swiper-container', {
                loop: true,
                pagination: {
                  el: '.swiper-pagination',
                },
              }
            );
            let mySwiperEl = document.querySelector('.swiper-container').swiper;
            let prev = document.getElementById('swiper-prev-btn');
            prev.onclick = function() {
              mySwiperEl.slidePrev();
            }
            let next = document.getElementById('swiper-next-btn');
            next.onclick = function() {
              mySwiperEl.slideNext();
            }
          },
          style: {
          }
        }
      });

      const modal = this.editor.Modal;
      const commands = this.editor.Commands;

      //this.editor.getConfig().showDevices = 0;
      //this.editor.Panels.addPanel({ id: "devices-c" }).get("buttons").add([
      //  { id: "set-device-desktop", command: function(e) { return e.setDevice("Desktop") }, className: "fa fa-desktop", active: 1 },
      //  { id: "set-device-tablet", command: function(e) { return e.setDevice("Tablet") }, className: "fa fa-tablet" },
      //  { id: "set-device-mobile", command: function(e) { return e.setDevice("Mobile portrait") }, className: "fa fa-mobile" },
      //  { id: "block-editor", command: function(e) { alert("hello world ") }, className: "fa fa-book" }
      //]);

      this.editor.Panels.addButton("options", {
        id: "btn-save-template",
        className: "fa fa-book",
        command: "openTutorial",
        attributes: { title: "Open Tutorial" }
      });

      this.editor.Commands.add("openTutorial", {
        run() {
          window.open(
            "https://www.youtube.com/watch?v=QTleqi5e-7g&ab_channel=Java%2Cjavaee%2Cjavafxerelacionados",
            "_blank"
          );
        }
      });

      /*commands.add("open-link-editor", {
        run: function(editor, sender) {
          console.log(sender);
          modal.setTitle("<div>Edit Link</div>");
          modal.setContent(`
            <div>
                <div class="gjs-label-wrp"><div class="gjs-label">Hyperlink (href):</div></div>
                <div class="gjs-field gjs-field-text"><input type="text" placeholder="eg. https://google.com"></div>
            </div>
        `);
          modal.open();
          modal.onceClose(() => {
            console.log(this.$el);
          });
        }
      });*/

      this.editor.on("storage:error:load", error => {
        // this.$router.back();
      });

      this.editor.Panels.getButton("views", "open-sm").set("active", true);

      this.editor.Commands.add("saveTemplate", {
        run(editor, sender) {
          vue.saveCurrentState();
          vue.savePages();
          try {
            form.post("/api/v1/other/create-landing");
          } catch (e) {
            console.log(e);
          }
          editor.store();
        }
      });

      this.editor.Panels.addButton("options", {
        id: "btn-save-template",
        className: "fa fa-save",
        command: "saveTemplate",
        attributes: {title: "Save template!"}
      });

      this.editor.Panels.removeButton("options", "sw-visibility");
      this.editor.runCommand('sw-visibility');
      this.editor.Panels.removeButton("options", "fullscreen");

      // simplify editor if user isn't admin
      if (this.user.user_type !== USER_TYPE_MASTER_ADMIN) {
        // remove panels
        this.editor.Panels.removeButton("views", "open-layers");
        this.editor.Panels.removeButton("views", "open-blocks");

        // remove btns
        this.editor.Panels.removeButton("options", "devices-c");
        this.editor.Panels.removeButton("options", "preview");

        // restrict remove/clone/move dom elements
        this.fixDom();

        // set settings panel is active
        this.editor.Panels.getButton("views", "open-tm").set("active", true);
      }

      // add custom fonts
      const styleManager = this.editor.StyleManager;
      const fontProperty = this.editor.StyleManager.getProperty('typography', 'font-family');
      let fontList = fontProperty.attributes.list;
      this.customFonts.forEach(function(font) {
        fontList.push({value: font, name: font});
      });
      fontProperty.set('list', fontList);
      styleManager.render();

      this.editor.on("storage:end:store", e => {
        this.saveAlert = true;
        setTimeout(() => {
          vue.saveAlert = false;
        }, 1000);
      });
    },
    initGrapesJsEvents() {
      this.editor.on("asset:upload:response", response => {});
      this.editor.on("asset:remove", async ({ attributes }) => {
        const assetPath = attributes["asset_path"];
        try {
          await this.$axios.post("/api/v1/gjs/destroy-asset", {
            asset_path: assetPath
          });
        } catch (e) {
          console.log(`file not deleted`, e);
        }
      });
    },
    async getAssets() {
      const { data } = await this.$axios.get("/api/v1/gjs/assets");
      this.grapesjsAssets = data;
    }
  }
};
