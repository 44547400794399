//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'
import ChatArea from "@/components/chat/ChatArea"
import ArrowBack from "@/components/icons/ArrowBack"
import FranchiseeDocumentList from "@/components/lists/FranchiseeDocumentList"

export default {
  middleware:[
    'accountFranchisee'
  ],
  components: {
    FranchiseeDocumentList,
    ChatArea,
    ArrowBack,
  },
  watchQuery: ['slug'],
  async asyncData({query, $axios, store, redirect}) {
    const redirectUrl = `/account/dashboard`
    const clientStepSlug = query.slug;
    const url = `/api/v1/business-client-steps/${clientStepSlug}/franchisee-step`;
    let clientStep;
    try {
      const {data} = await $axios.get(url);
      clientStep = data
    } catch (e) {
      console.log(e);
      redirect(redirectUrl)
    }
    let franchiseName = null;
    let franchiseLogo = null;
    let franchiseId = null;

    store.commit('chat/SET_CHAT_CONTACT_MESSAGES_PAGINATION', {
      current_page: 1,
    });
    store.commit('chat/SET_CHAT_CONTACT_MESSAGES', []);

    try {
      await store.dispatch('chat/fetchContactMessages', clientStep.slug);
      await store.dispatch('franchisee-steps/fetchStepDocuments', clientStep.business_step_id);
    } catch (e) {
      console.log(e);
      redirect(redirectUrl)
    }

    try {
      const franchiseResponse = await $axios.get(
        `/api/v1/business/${clientStep.franchise_id}/data/latest`
      );
      const franchise = franchiseResponse.data.data[0];
      franchiseId = franchise.franchise_id;
      franchiseLogo = franchise.logo.mini;
      franchiseName = franchise.business_name.attribute_value_string;
    } catch (e) {
      console.log(e)
      redirect(redirectUrl)
    }
    return {
      clientStepSlug,
      clientStep,
      franchiseName,
      franchiseLogo,
      franchiseId,
    };
  },
  data() {
    return {
      messagesLoading: false,
      mobileTabs: [
        {
          title: 'Messages',
          id: 'chat__contacts',
        },
        {
          title: 'Documents',
          id: 'chat__documents',
        },
      ],
      activeMobileTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      contactMessages: 'chat/contactMessages',
      contactMessagesLoading: 'chat/contactMessagesLoading',
      contactMessagesPagination: 'chat/contactMessagesPagination',
      documentsLoading: 'franchisee-steps/businessStepDocumentLoading',
      documentList: 'franchisee-steps/businessStepDocumentList',
      user: 'auth/user',
    }),
  },
  head() {
    const title = `Franchisee Chat | ${this.franchiseName}`
    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
      ]
    }
  },
};
