import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=2ad2fbc2&scoped=true&"
import script from "./reset.vue?vue&type=script&lang=js&"
export * from "./reset.vue?vue&type=script&lang=js&"
import style0 from "./reset.vue?vue&type=style&index=0&id=2ad2fbc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad2fbc2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VButton: require('E:/all-projects/franchise-digital-platform-spa/components/VButton.vue').default})
