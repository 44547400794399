//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContactPreRegister from "@/components/modals/ModalContactPreRegister";
import VButton from "@/components/VButton";

export default {
  name:'FranchiseUniversityPage',
  components: {
    VButton,
    ModalContactPreRegister,
  },
  data () {
    return {
      preRegisterModalShow: false,
    }
  },
  mounted() {
    this.$store.dispatch('account/setShowPreloader', false);
  },
  methods:{
    preRegister () {
      this.preRegisterModalShow = false
      this.preRegisterModalShow = true
    }
  }
}
