//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import FranchiseUniversityImage from 'assets/images/franchise-university/FranchiseUniversityImage.svg?inline';
import CertificateFranchisorImage from 'assets/images/franchise-university/CertificateFranchisorImage.svg?inline';
import CertificateFranchiseeImage from 'assets/images/franchise-university/CertificateFranchiseeImage.svg?inline';
import ModalContactPreRegister from '~/components/modals/ModalContactPreRegister';

export default {
  components: {
    ModalContactPreRegister,
    Swiper,
    SwiperSlide,
    FranchiseUniversityImage,
    CertificateFranchisorImage,
    CertificateFranchiseeImage,
  },
  data() {
    return {
      preRegisterModalShow: false,
      swiperData: [
        {
          number: '01',
          franchising: 'Franchising 101',
          industry: [
            'Industry overview',
            'Personality profiling',
            'Industry definitions',
            'Franchise disclosures',
            'The history of franchising',
          ],
        },
        {
          number: '02',
          franchising: 'Franchising 201',
          industry: [
            'Building your team',
            'Franchise development',
            'Growth strategies',
            'Strategic partnerships',
            'Franchise launch',
          ],
        },
        {
          number: '03',
          franchising: 'Franchising 301',
          industry: [
            'Legal strategy',
            'Expansion strategy',
            'Buying a franchise location',
            'Multi-unit strategy',
          ],
        },
      ],
      swiperOptions: {
        slidesPerView: 1.13,
        spaceBetween: 16,
        slidesPerGroup: 1,
        autoHeight: true,
        loop: true,
        watchSlidesVisibility: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  methods: {
    preRegister() {
      this.preRegisterModalShow = false;
      this.preRegisterModalShow = true;
    },
  },
  head() {
    const title = 'Franchise University Certification';
    const descr =
      'Become an expert in franchising with our Franchise Training & Educational Program. From basics to building a successful multi-unit business. Start now!';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        },
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: descr,
        },
      ],
    };
  },
};
