//
//
//
//

import storeMixin from "@/components/mixins/storeMixin";

export default {
  name: "IndexVue",
  mixins: [
    storeMixin
  ],
  beforeMount() {
    const uri = this.buildAccountFranchisorUri(
      `/account-franchisor/${this.$route.params.franchiseId}/steps/${this.$route.params.step}`
    )
    this.$router.push(uri)
  }
}
