import { render, staticRenderFns } from "./index.vue?vue&type=template&id=16a062f3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=16a062f3&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a062f3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ViewSwitcher: require('E:/all-projects/franchise-digital-platform-spa/components/ViewSwitcher.vue').default,Loader: require('E:/all-projects/franchise-digital-platform-spa/components/Loader.vue').default})
