//
//
//
//
//

import DashboardFranchiseeProfile from "@/components/dashboards/viewFranchisee/DashboardFranchiseeProfile";
import {mapActions} from "vuex";

export default {
  name: 'ProfilePage',
  components: {DashboardFranchiseeProfile},
  created() {
    this.setShowPreloader(true)
    this.fetchAnswers()
  },
  methods: {
    ...mapActions({
      setShowPreloader: 'account/setShowPreloader',
      fetchAnswers: 'account/answers/fetchAnswers',
    }),
  }
}
