//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import ArrowIcon from '@/assets/icons/arrows/ArrowIcon.svg?inline';
import DirectoryCard from '@/components/cards/DirectoryCard';
import VBTable from '@/components/tables/VBTable';

export default {
  name: 'TopFranchisesCategoryPage',
  components: {
    VBTable,
    DirectoryCard,
    ArrowIcon,
  },
  asyncData(ctx) {
    ctx.store.dispatch('top-franchise/updateCurrentSlug', ctx.route.params.category);
    ctx.store.dispatch('top-franchise/fetchTopList');
  },
  data: () => ({
    tableView: false,
  }),
  computed: {
    ...mapGetters({
      currentTopList: 'top-franchise/currentTopList',
      items: 'top-franchise/franchiseListArr',
      pagination: 'top-franchise/pagination',
      busy: 'top-franchise/busy',
      busyFetchFranchise: 'top-franchise/busyFetchFranchise',
      authenticated: 'auth/check',
      user: 'auth/user',
    }),
    isLoading() {
      return this.busy || this.busyFetchFranchise;
    },
    getTitle() {
      return this.currentTopList?.title ?? '';
    },
    getDetailContent() {
      return this.currentTopList?.detail_content ?? '';
    },
    getCounter() {
      return `${this.pagination?.total ?? 0} Franchises`;
    },
    tableViewFields() {
      return [
        { key: 'index', label: '#', stickyColumn: true },
        {
          key: 'business_image',
          label: '',
          sortable: false,
          class: 'b-image',
        },
        {
          key: 'business_name',
          label: 'Franchise Name',
          sortable: true,
          stickyColumn: true,
          sortByFormatted: true,
          class: 'b-name',
        },
        { key: 'investment', sortable: true },
        { key: 'average_revenue', sortable: true },
        { key: 'franchise_fee', sortable: true },
        {
          key: this.authenticated && this.user.isMasterAdmin ? 'admin_button' : 'compare',
          label: this.authenticated && this.user.isMasterAdmin ? 'Admin' : 'Compare',
          sortable: false,
        },
        'FDD',
      ];
    },
  },
  mounted() {
    this.updateCurrentSlug(this.$route.params.category);
    this.fetchTopList();
  },
  methods: {
    ...mapActions({
      updateCurrentSlug: 'top-franchise/updateCurrentSlug',
      fetchTopList: 'top-franchise/fetchTopList',
    }),
  },
  head() {
    return {
      title: this.currentTopList?.seo?.title ?? 'Top franchise',
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        },
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: this.currentTopList?.seo?.description ?? '',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.currentTopList?.seo?.title ?? '',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.currentTopList?.seo?.title ?? '',
        },
      ],
    };
  },
};
