import { render, staticRenderFns } from "./radio.vue?vue&type=template&id=50195de5&scoped=true&"
import script from "./radio.vue?vue&type=script&lang=js&"
export * from "./radio.vue?vue&type=script&lang=js&"
import style0 from "./radio.vue?vue&type=style&index=0&id=50195de5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50195de5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogHome: require('E:/all-projects/franchise-digital-platform-spa/components/BlogHome.vue').default})
