//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContactFormPcmania from "~/components/form/ContactFormPcmania"
import ReviewCard from "~/components/ReviewCard"
import StepOne from "~/assets/images/pcmania/step-one.svg?inline"
import StepTwo from "~/assets/images/pcmania/step-two.svg?inline"
import StepThree from "~/assets/images/pcmania/step-three.svg?inline"
import StepsArrow from "~/assets/images/pcmania/steps-arrow.svg?inline"

export default {
  name: "Pcmania",
  components: {
    ContactFormPcmania,
    StepOne,
    StepTwo,
    StepThree,
    StepsArrow,
    ReviewCard
  },
  methods: {
    head() {
      const title = 'Pcmania Franchise123';
      const descr = 'Research thousands of franchise opportunities to find the perfect match!';
      const keywords = '';
      return {
        title,
        link: [
          {
            rel: 'canonical',
            href: process.env.APP_URL + this.$route.path,
          }
        ],
        meta: [
          {
            name: 'description',
            hid: 'description',
            content: descr,
          },
          {
            name: 'keywords',
            hid: 'keywords',
            content: keywords,
          },
          {
            hid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            hid: "og:description",
            property: "og:description",
            content: descr,
          },
        ]
      }
    }
  }
}
