//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import ModalViewFranchisee from "@/components/modals/franchisor/ModalViewFranchisee"
import storeMixin from "@/components/mixins/storeMixin"
import ArrowLeft from '@/assets/images/svg/arrow-left-green.svg?inline'
import ModalAddDocument from '@/components/modals/ModalAddDocument'
import ChatArea from "@/components/chat/ChatArea";
import ArrowBack from "@/components/icons/ArrowBack"
import DocumentList from "@/components/lists/DocumentList"
import LinkButton from "@/components/buttons/LinkButton"
import ChatContactAvatar from "@/components/chat/ChatContactAvatar"

import {
  MODAL_VIEW_FRANCHISEE_INFO,
} from "@/store/constants"

export default {
  name: 'PageAccountFranchisorChatSlug',
  components: {
    ModalViewFranchisee,
    ChatContactAvatar,
    LinkButton,
    DocumentList,
    ArrowBack,
    ChatArea,
    ArrowLeft,
    ModalAddDocument
  },
  mixins: [
    storeMixin,
  ],
  async asyncData({store, route, redirect}) {
    let redirectUrl = ''

    try {
      await store.dispatch('franchisor-steps/fetchBusinessStep', {
        slug: route.params.step,
        franchiseId: route.params.franchiseId
      })
      if (!store.getters['franchisor-steps/businessStep']) {
        redirectUrl = `/account-franchisor/${route.params.franchiseId}/steps/opportunity`
      }

      await store.dispatch('chat/fetchContact', route.params.slug);
      if (!store.getters['chat/chosenContact']) {
        redirectUrl = `/account-franchisor/${route.params.franchiseId}/steps/opportunity`
      }
    } catch (e) {
      redirectUrl = `/account-franchisor/${route.params.franchiseId}/steps/opportunity`
    }

    if (redirectUrl) {
      redirect(redirectUrl)
    }
  },
  data: () => ({
    documentList: [],
    documentUploadLoading: false,
    showModalNewDocument: false,
  }),
  computed: {
    ...mapGetters({
      businessStep: 'franchisor-steps/businessStep',
      contactMessages: 'chat/contactMessages',
      contactMessagesLoading: 'chat/contactMessagesLoading',
      contactMessagesPagination: 'chat/contactMessagesPagination',
      chosenContact: 'chat/chosenContact',
      paymentPermissions: 'payment/permissions'
    }),
  },
  async mounted() {
    await this.$store.dispatch('chat/fetchContactMessages', this.$route.params.slug)
    await this.fetchDocuments();
  },
  methods: {
    ...mapActions({
      fetchBusinessStep: 'franchisor-steps/fetchBusinessStep',
      fetchContact: 'chat/fetchContact',
    }),
    handleClickOpenModal(){
      this.updateFranchiseeViewedId(this.chosenContact)
      this.$bvModal.show(MODAL_VIEW_FRANCHISEE_INFO)
    },
    handleBack() {
      this.$router.push(
        this.buildAccountFranchisorUri(`/account-franchisor/${this.$route.params.franchiseId}/steps/${this.$route.params.step}`)
      );
    },
    /**
     * Click add new document handler
     */
    handleClickAddNew() {
      if (this.user.isFranchisor && !this.paymentPermissions.addDocumentToStep) {
        this.fireModalMessage({message: this.paymentPermissions.addDocumentToStep.message})
        return
      }

      this.showModalNewDocument = !this.showModalNewDocument;
    },
    /**
     * Adding document event
     * @param {Array} documents uploaded document
     */
    onDocumentAdd() {
      this.showModalNewDocument = false;
      this.fetchDocuments()
    },
    /**
     * Fetch business documents by business step id
     */
    async fetchDocuments() {
      this.documentUploadLoading = true;
      const {data} = await this.$axios.get(
        `/api/v1/business-step-docs/${this.businessStep.business_step_id}`
      );
      this.documentList = data;
      this.documentUploadLoading = false;
    },
  }
};
