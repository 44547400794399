import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=c373d308&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=c373d308&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c373d308",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountFranchisorDashboardCard: require('E:/all-projects/franchise-digital-platform-spa/components/account-franchisor/cards/AccountFranchisorDashboardCard.vue').default,AccountFranchisorStepper: require('E:/all-projects/franchise-digital-platform-spa/components/account-franchisor/AccountFranchisorStepper.vue').default,AccountFranchisorLayout: require('E:/all-projects/franchise-digital-platform-spa/components/account-franchisor/AccountFranchisorLayout.vue').default})
